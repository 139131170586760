import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import i18next from "i18next";
import React, { useCallback, useState } from "react";
import Barcode from "react-barcode";
import { Col, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import DropDown from "../../components/DropDown";
import Loader from "../../components/Loader/Loader";
import NavigateBack from "../../components/NavigateBack";
import { decimalOnly, unitsArr } from "../../helper/helper";
import useDetails from "../../hooks/useDetails";
import useSlider from "../../hooks/useSlider";
import {
  addItem,
  addItemImages,
  editItem,
  getCategory,
  getItemDetails,
} from "../../services/services";
import { UploadIcon } from "../../SvgIcons/allIcons";
import { generateDayHourMinutes } from "../../utils/common";
import { constant } from "../../utils/constants";
import { toastAlert } from "../../utils/SweetAlert";
import Sidebar from "../sidebar/Sidebar";

const AddItem = () => {
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const id = searchParam.get("id");
  const isSlider = useSlider();
  const { t } = useTranslation();
  const details = useDetails();
  const [showBarCode, setShowBarCode] = useState(false);
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    setValues,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      basicInfo: {
        en_name: "",
        es_name: "",
        category: "",
        locations: [],
        allLocation: false,
        expirationTime: {
          days: { label: 0, value: 0 },
          hour: { label: 0, value: 0 },
          min: { label: 0, value: 0 },
        },
        thawExpiration: {
          days: { label: 0, value: 0 },
          hour: { label: 0, value: 0 },
          min: { label: 0, value: 0 },
        },
        endOfDayExpiration: "false",
        thawEndOfDayExporation: "false",
      },
      specificLabel: {
        needToPrint: "false",
        chooseLabel: "",
      },
      wasteTracking: {
        productWasteTracking: "false",
        unitOfMeasure: { label: "Bag(s)", value: "bag" },
        costOfGoods: 0,
      },
      additionalInfo: {
        sku: "",
        itemSellPrice: 0,
        netWeight: 0,
        customField: "",
        description: "",
        barCode: 0,
        barCodeImg: "",
        qrCode: "",
        newPickedBarcode: "",
        newPickedQrCode: "",
      },
      nutritionalInfo: {
        ingredients: "",
        allergens: "",
        servingSize: 0,
        servingPerContainer: 0,
        calories: 0,
        totalFat: 0,
        saturatedFat: 0,
        transFat: 0,
        caloriesFromFat: 0,
        cholesterol: 0,
        carbohydrates: 0,
        protein: 0,
        dietaryFiber: 0,
        totalSugar: 0,
        addedSugar: 0,
        sodium: 0,
        vitaminA: 0,
        vitaminC: 0,
        vitaminD: 0,
        vitaminDPercent: 0,
        calcium: 0,
        iron: 0,
        ironPercent: 0,
        potassium: 0,
        potassiumPercent: 0,
      },
    },
    validationSchema: yup.object().shape({
      basicInfo: yup.object().shape({
        en_name: yup
          .string()
          .required()
          .label(t("item.itemName"))
          .trim()
          .min(2),
        // es_name: yup
        //   .string()
        //   // .required()
        //   .label(t("item.itemNameSpanish"))
        //   .trim()
        //   .min(2),
        category: yup.object().shape({
          value: yup.string().required().label(t("item.category")),
        }),
        locations: yup.array().when("allLocation", {
          is: (value) => !value,
          then: () => yup.array().min(1).label(t("item.location")),
        }),
      }),
      additionalInfo: yup.object().shape({
        newPickedBarcode: yup.mixed().when(([newPickedBarcode], schema) => {
          if (newPickedBarcode) {
            return yup
              .mixed()
              .test("type", t("errorMsg.fileFormat"), function (value) {
                return (
                  value &&
                  (value.type === "image/jpg" ||
                    value.type === "image/png" ||
                    value.type === "image/jpeg")
                );
              });
          }
          return schema;
        }),
        newPickedQrCode: yup.mixed().when(([newPickedQrCode], schema) => {
          if (newPickedQrCode) {
            return yup
              .mixed()
              .test("type", t("errorMsg.fileFormat"), function (value) {
                return (
                  value &&
                  (value.type === "image/jpg" ||
                    value.type === "image/png" ||
                    value.type === "image/jpeg")
                );
              });
          }
          return schema;
        }),
      }),
    }),
    onSubmit: (values) => {
      if (
        !!values?.additionalInfo?.newPickedQrCode ||
        !!values?.additionalInfo?.newPickedBarcode
      ) {
        const filesToUpload = [
          values?.additionalInfo?.newPickedQrCode && {
            type: constant.IMAGE_TYPE.QR,
            file: values.additionalInfo?.newPickedQrCode,
          },
          values?.additionalInfo?.newPickedBarcode && {
            type: constant.IMAGE_TYPE.BAR,
            file: values?.additionalInfo?.newPickedBarcode,
          },
        ].filter(Boolean);
        imageMutation.mutate(filesToUpload);
      } else {
        let body = {
          en_name: values?.basicInfo?.en_name,
          es_name: values?.basicInfo?.es_name,
          categoryId: values?.basicInfo?.category?.value,
          assignAll: values?.basicInfo?.allLocation,
          eodExpiration: values?.basicInfo?.endOfDayExpiration,
          eodThrawExpiration: values?.basicInfo?.thawEndOfDayExporation,
          expirationTime: {
            days: values?.basicInfo?.expirationTime?.days?.value,
            hours: values?.basicInfo?.expirationTime?.hour?.value,
            mins: values?.basicInfo?.expirationTime?.min?.value,
          },
          thawExpiration: {
            days: values?.basicInfo?.thawExpiration?.days?.value,
            hours: values?.basicInfo?.thawExpiration?.hour?.value,
            mins: values?.basicInfo?.thawExpiration?.min?.value,
          },
          specificLabel: {
            enabled: values?.specificLabel?.needToPrint,
            chooseLabel: values?.specificLabel?.chooseLabel,
          },
          wasteTracking: {
            enabled: values?.wasteTracking?.productWasteTracking,
            costOfGoods: values?.wasteTracking?.costOfGoods,
            measureUnit: values?.wasteTracking?.unitOfMeasure?.value,
          },
          additionalInfo: {
            sku: values?.additionalInfo?.sku,
            netWeight: values?.additionalInfo?.netWeight,
            description: values?.additionalInfo?.description,
            sellingPrice: values?.additionalInfo?.itemSellPrice,
            barCodeNumber: values?.additionalInfo?.barCode
              ? values?.additionalInfo?.barCode
              : 0,
            customFieldValue: values?.additionalInfo?.customField,
          },
          nutritionalInfo: {
            iron: values?.nutritionalInfo?.iron,
            sodium: values?.nutritionalInfo?.sodium,
            protein: values?.nutritionalInfo?.protein,
            calcium: values?.nutritionalInfo?.calcium,
            calories: values?.nutritionalInfo?.calories,
            totalFat: values?.nutritionalInfo?.totalFat,
            transFat: values?.nutritionalInfo?.transFat,
            vitaminA: values?.nutritionalInfo?.vitaminA,
            vitaminC: values?.nutritionalInfo?.vitaminC,
            vitaminD: values?.nutritionalInfo?.vitaminD,
            allergens: values?.nutritionalInfo?.allergens,
            potassium: values?.nutritionalInfo?.potassium,
            totalSugar: values?.nutritionalInfo?.totalSugar,
            addedSugar: values?.nutritionalInfo?.addedSugar,
            servingSize: values?.nutritionalInfo?.servingSize,
            cholesterol: values?.nutritionalInfo?.cholesterol,
            ironPercent: values?.nutritionalInfo?.ironPercent,
            ingredients: values?.nutritionalInfo?.ingredients,
            saturatedFat: values?.nutritionalInfo?.saturatedFat,
            dietaryFiber: values?.nutritionalInfo?.dietaryFiber,
            carbohydrates: values?.nutritionalInfo?.carbohydrates,
            caloriesFromFat: values?.nutritionalInfo?.caloriesFromFat,
            vitaminDPercent: values?.nutritionalInfo?.vitaminDPercent,
            potassiumPercent: values?.nutritionalInfo?.potassiumPercent,
            servingPerContainer: values?.nutritionalInfo?.servingPerContainer,
          },
        };
        if (!!id && values?.additionalInfo?.barCodeImg) {
          body.additionalInfo.barCodeImage = values?.additionalInfo?.barCodeImg;
        }
        if (!!id && values?.additionalInfo?.qrCode) {
          body.additionalInfo.qrCodeImage = values?.additionalInfo?.qrCode;
        }
        if (!values?.basicInfo?.allLocation)
          body.locations = values?.basicInfo?.locations?.map((i) => i.value);

        mutation.mutate(body);
      }
    },
  });

  const imageMutation = useMutation({
    mutationFn: async (body) => {
      let imageUrl = [];

      const uploadPromises = body?.map(async (item) => {
        let formData = new FormData();
        formData.append("file", item?.file);

        const resp = await addItemImages(item?.type, formData);
        imageUrl.push({
          url: resp?.data?.data?.imageUrl,
          type: item?.type,
        });
      });

      await Promise.all(uploadPromises);

      return imageUrl;
    },
    onSuccess: (resp) => {
      let body = {
        en_name: values?.basicInfo?.en_name,
        es_name: values?.basicInfo?.es_name,
        categoryId: values?.basicInfo?.category?.value,
        assignAll: values?.basicInfo?.allLocation,
        eodExpiration: values?.basicInfo?.endOfDayExpiration,
        eodThrawExpiration: values?.basicInfo?.thawEndOfDayExporation,
        expirationTime: {
          days: values?.basicInfo?.expirationTime?.days?.value,
          hours: values?.basicInfo?.expirationTime?.hour?.value,
          mins: values?.basicInfo?.expirationTime?.min?.value,
        },
        thawExpiration: {
          days: values?.basicInfo?.thawExpiration?.days?.value,
          hours: values?.basicInfo?.thawExpiration?.hour?.value,
          mins: values?.basicInfo?.thawExpiration?.min?.value,
        },
        specificLabel: {
          enabled: values?.specificLabel?.needToPrint,
          chooseLabel: values?.specificLabel?.chooseLabel,
        },
        wasteTracking: {
          enabled: values?.wasteTracking?.productWasteTracking,
          costOfGoods: values?.wasteTracking?.costOfGoods,
          measureUnit: values?.wasteTracking?.unitOfMeasure?.value,
        },
        additionalInfo: {
          sku: values?.additionalInfo?.sku,
          netWeight: values?.additionalInfo?.netWeight,
          description: values?.additionalInfo?.description,
          sellingPrice: values?.additionalInfo?.itemSellPrice,
          barCodeNumber: values?.additionalInfo?.barCode
            ? values?.additionalInfo?.barCode
            : 0,
          customFieldValue: values?.additionalInfo?.customField,
        },
        nutritionalInfo: {
          iron: values?.nutritionalInfo?.iron,
          sodium: values?.nutritionalInfo?.sodium,
          protein: values?.nutritionalInfo?.protein,
          calcium: values?.nutritionalInfo?.calcium,
          calories: values?.nutritionalInfo?.calories,
          totalFat: values?.nutritionalInfo?.totalFat,
          transFat: values?.nutritionalInfo?.transFat,
          vitaminA: values?.nutritionalInfo?.vitaminA,
          vitaminC: values?.nutritionalInfo?.vitaminC,
          vitaminD: values?.nutritionalInfo?.vitaminD,
          allergens: values?.nutritionalInfo?.allergens,
          potassium: values?.nutritionalInfo?.potassium,
          totalSugar: values?.nutritionalInfo?.totalSugar,
          addedSugar: values?.nutritionalInfo?.addedSugar,
          servingSize: values?.nutritionalInfo?.servingSize,
          cholesterol: values?.nutritionalInfo?.cholesterol,
          ironPercent: values?.nutritionalInfo?.ironPercent,
          ingredients: values?.nutritionalInfo?.ingredients,
          saturatedFat: values?.nutritionalInfo?.saturatedFat,
          dietaryFiber: values?.nutritionalInfo?.dietaryFiber,
          carbohydrates: values?.nutritionalInfo?.carbohydrates,
          caloriesFromFat: values?.nutritionalInfo?.caloriesFromFat,
          vitaminDPercent: values?.nutritionalInfo?.vitaminDPercent,
          potassiumPercent: values?.nutritionalInfo?.potassiumPercent,
          servingPerContainer: values?.nutritionalInfo?.servingPerContainer,
        },
      };
      if (!values?.basicInfo?.allLocation)
        body.locations = values?.basicInfo?.locations?.map((i) => i.value);

      let qrImage = resp?.find((i) => i.type == constant.IMAGE_TYPE.QR);
      if (!!qrImage) body.additionalInfo.qrCodeImage = qrImage?.url;

      let barImage = resp?.find((i) => i.type == constant.IMAGE_TYPE.BAR);
      if (!!barImage) body.additionalInfo.barCodeImage = barImage?.url;

      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: (body) =>
      !!id
        ? editItem(id, body, details?.companyUID)
        : addItem(body, details?.companyUID),
    onSuccess: (resp) => {
      navigate("../items");
      toastAlert("success", resp?.data?.message);
    },
  });

  const onBarDrop = useCallback((e) => {
    setFieldValue("additionalInfo.newPickedBarcode", e[0]);
    setShowBarCode(false);
    setFieldValue("additionalInfo.barCode", "");
  }, []);

  const onBarRejected = useCallback((e) => {
    setFieldValue("additionalInfo.newPickedBarcode", e[0]?.file);
  });

  const barCodeDrop = useDropzone({
    onDrop: onBarDrop,
    onDropRejected: onBarRejected,
    accept: { "image/*": [] },
    multiple: false,
  });

  const onDrop = useCallback((e) => {
    setFieldValue("additionalInfo.newPickedQrCode", e[0]);
  }, []);

  const onDropRejected = useCallback((e) => {
    setFieldValue("additionalInfo.newPickedQrCode", e[0]?.file);
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: { "image/*": [] },
    multiple: false,
  });

  const loadLocations = async (location) => {
    try {
      let array =
        ((await values?.basicInfo?.category?.locations?.length) > 0
          ? values?.basicInfo?.category?.locations
          : details?.userDetails?.userLocations?.map((i) => ({
              label: i?.name,
              value: i?._id,
            }))) ?? [];
      return {
        options: array,
        hasMore: false,
      };
    } catch (err) {
      console.log("err", err);
    }
  };

  const loadCategories = async (search, loadedOptions, { page }) => {
    let resp = await getCategory(
      details?.companyUID,
      page,
      constant.PER_PAGE_TEN,
      search,
      true
    );

    let array = (await resp?.data?.data?.categories) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: i18next?.language == "es" ? item?.es_name : item?.en_name,
        locations: item?.locations?.map((i) => ({
          label: i?.name,
          value: i?._id,
        })),
        allLocation: item?.assignAll,
      })),
      hasMore: loadedOptions.length == resp?.data?.data?.total ? false : true,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadDays = async () => {
    const options = generateDayHourMinutes(365);
    return {
      options,
      hasMore: false,
    };
  };

  const loadHours = async () => {
    const options = generateDayHourMinutes(23);
    return {
      options,
      hasMore: false,
    };
  };

  const loadMinutes = async () => {
    const options = generateDayHourMinutes(59);
    return {
      options,
      hasMore: false,
    };
  };

  const loadUnits = async () => {
    return {
      options: unitsArr,
      hasMore: false,
    };
  };

  useQuery({
    queryKey: ["item-details", id],
    queryFn: async () => {
      const resp = !!id && (await getItemDetails(id, details?.companyUID));
      let data = resp?.data?.data;

      if (data) {
        setValues({
          ...values,
          basicInfo: {
            en_name: data?.es_name,
            es_name: data?.en_name,
            category: {
              value: data?.categoryDetails?._id,
              label:
                i18next?.language == "es"
                  ? data?.categoryDetails?.es_name
                  : data?.categoryDetails?.en_name,
              locations: details?.userDetails?.userLocations
                ?.filter((i) => data?.categoryDetails?.locations?.includes(i))
                ?.map((i) => ({
                  label: i?.name,
                  value: i?._id,
                })),
              allLocation: data?.categoryDetails?.assignAll,
            },
            locations: data?.locations?.map((i) => ({
              label: i.name,
              value: i._id,
            })),
            allLocation: data?.assignAll,
            expirationTime: {
              days: {
                label: data?.expirationTime?.days,
                value: data?.expirationTime?.days,
              },
              hour: {
                label: data?.expirationTime?.hours,
                value: data?.expirationTime?.hours,
              },
              min: {
                label: data?.expirationTime?.hours,
                value: data?.expirationTime?.hours,
              },
            },
            thawExpiration: {
              days: {
                label: data?.thawExpiration?.days,
                value: data?.thawExpiration?.days,
              },
              hour: {
                label: data?.thawExpiration?.hours,
                value: data?.thawExpiration?.hours,
              },
              min: {
                label: data?.thawExpiration?.hours,
                value: data?.thawExpiration?.hours,
              },
            },
            endOfDayExpiration: data?.eodExpiration ? "true" : "false",
            thawEndOfDayExporation: data?.eodThrawExpiration ? "true" : "false",
          },
          specificLabel: {
            needToPrint: data?.specificLabel?.enabled ? "true" : "false",
            chooseLabel: data?.specificLabel?.chooseLabel
              ? data?.specificLabel?.chooseLabel
              : "",
          },
          wasteTracking: {
            productWasteTracking: data?.wasteTracking?.enabled,
            unitOfMeasure: unitsArr.find(
              (i) => i?.value == data?.wasteTracking?.measureUnit
            ),
            costOfGoods: data?.wasteTracking?.costOfGoods,
          },
          additionalInfo: {
            sku: data?.additionalInfo?.sku,
            itemSellPrice: data?.additionalInfo?.sellingPrice,
            netWeight: data?.additionalInfo?.netWeight,
            customField: data?.additionalInfo?.additionalInfo,
            description: data?.additionalInfo?.description,
            barCode: data?.additionalInfo?.barCodeNumber,
            qrCode: data?.additionalInfo?.qrCodeImage,
            barCodeImg: data?.additionalInfo?.barCodeImage,
          },
          nutritionalInfo: {
            ingredients: data?.nutritionalInfo?.ingredients,
            allergens: data?.nutritionalInfo?.allergens,
            servingSize: data?.nutritionalInfo?.servingSize,
            servingPerContainer: data?.nutritionalInfo?.servingPerContainer,
            calories: data?.nutritionalInfo?.calories,
            totalFat: data?.nutritionalInfo?.totalFat,
            saturatedFat: data?.nutritionalInfo?.saturatedFat,
            transFat: data?.nutritionalInfo?.transFat,
            caloriesFromFat: data?.nutritionalInfo?.caloriesFromFat,
            cholesterol: data?.nutritionalInfo?.cholesterol,
            carbohydrates: data?.nutritionalInfo?.carbohydrates,
            protein: data?.nutritionalInfo?.protein,
            dietaryFiber: data?.nutritionalInfo?.dietaryFiber,
            totalSugar: data?.nutritionalInfo?.totalSugar,
            addedSugar: data?.nutritionalInfo?.addedSugar,
            sodium: data?.nutritionalInfo?.sodium,
            vitaminA: data?.nutritionalInfo?.vitaminA,
            vitaminC: data?.nutritionalInfo?.vitaminC,
            vitaminD: data?.nutritionalInfo?.vitaminD,
            vitaminDPercent: data?.nutritionalInfo?.vitaminDPercent,
            calcium: data?.nutritionalInfo?.calcium,
            iron: data?.nutritionalInfo?.iron,
            ironPercent: data?.nutritionalInfo?.ironPercent,
            potassium: data?.nutritionalInfo?.potassium,
            potassiumPercent: data?.nutritionalInfo?.potassiumPercent,
          },
        });
        if (data?.additionalInfo?.barCodeNumber) {
          setShowBarCode(true);
        }
      }
      return true;
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={12}>
            <NavigateBack>
              {!!id ? t("item.editItem") : t("item.addNewItem")}
            </NavigateBack>
          </Col>
        </Row>
        <div className="basic_Info">
          <section>
            <Row>
              <h3 className="fontSize16">{t("item.basicInfo")}</h3>
              <Col md={6}>
                <div className="mt-2">
                  <label htmlFor="basicInfo.en_name" className="companyLabel">
                    {t("item.itemName")} <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="basicInfo.en_name"
                    name="basicInfo.en_name"
                    value={values?.basicInfo?.en_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="commonInput"
                    placeholder={t("item.enterItemName")}
                  />
                  <small className="text-danger ms-1">
                    {touched?.basicInfo?.en_name && errors?.basicInfo?.en_name}
                  </small>
                </div>
              </Col>
              <Col md={6}>
                <div className="mt-2">
                  <label htmlFor="basicInfo.es_name" className="companyLabel">
                    {t("item.itemNameSpanish")}
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <input
                    type="text"
                    id="basicInfo.es_name"
                    name="basicInfo.es_name"
                    value={values?.basicInfo?.es_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="commonInput"
                    placeholder={t("item.enterItemNameSpanish")}
                  />
                  <small className="text-danger ms-1">
                    {touched?.basicInfo?.es_name && errors?.basicInfo?.es_name}
                  </small>
                </div>
              </Col>
              <Col md={12}>
                <div className="mt-3">
                  <label htmlFor="name" className="companyLabel">
                    {t("item.category")}
                    <span className="text-danger">*</span>
                  </label>
                  <DropDown
                    placeholder={t("item.selectCategory")}
                    id="basicInfo.category"
                    loadOptions={loadCategories}
                    isClearable={false}
                    value={values?.basicInfo?.category}
                    onChange={(e) => {
                      setFieldValue("basicInfo.category", e);
                      setFieldValue("basicInfo.locations", []);
                      setFieldValue("basicInfo.allLocation", e.allLocation);
                    }}
                    onBlur={() => setFieldTouched("basicInfo.category", true)}
                  />
                  <small className="text-danger ms-1">
                    {touched?.basicInfo?.category &&
                      errors?.basicInfo?.category?.value}
                  </small>
                </div>
              </Col>
              {!!values?.basicInfo?.category && (
                <>
                  <Col md={12}>
                    <div className="mt-2">
                      <label htmlFor="name" className="companyLabel">
                        {t("item.location")}
                        <span className="text-danger">*</span>
                      </label>
                      <DropDown
                        placeholder={t("item.enterLocationName")}
                        id="basicInfo.locations"
                        loadOptions={loadLocations}
                        isClearable={false}
                        defaultOptions={true}
                        depends={values?.basicInfo?.category}
                        isDisabled={values?.basicInfo?.allLocation == true}
                        isMulti={true}
                        value={values?.basicInfo?.locations}
                        onChange={(e) =>
                          setFieldValue("basicInfo.locations", e)
                        }
                        onBlur={() =>
                          setFieldTouched("basicInfo.locations", true)
                        }
                      />
                      <small className="text-danger ms-1">
                        {touched?.basicInfo?.locations &&
                          errors?.basicInfo?.locations}
                      </small>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="customer_Access mt-2">
                      <div className="access_Child mt-0">
                        <div className="form-group">
                          <input
                            type="checkbox"
                            id="allLocation"
                            name="basicInfo.allLocation"
                            value={values?.basicInfo?.allLocation}
                            checked={values?.basicInfo?.allLocation}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          />
                          <label htmlFor="allLocation">
                            <span className="fontSize14 ms-2">
                              {t("item.applyToAllFutureLocations")}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>
                </>
              )}

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="name" className="companyLabel">
                    {t("item.expirationTime")}
                  </label>
                  <div className="d-flex justify-content-between pe-4">
                    <div className="d-flex align-items-center">
                      <DropDown
                        placeholder={"0"}
                        loadOptions={loadDays}
                        isClearable={false}
                        defaultOptions={true}
                        components={{ IndicatorSeparator: () => null }}
                        value={values?.basicInfo?.expirationTime?.days}
                        onChange={(e) =>
                          setFieldValue("basicInfo.expirationTime.days", e)
                        }
                        onBlur={() =>
                          setFieldTouched("basicInfo.expirationTime.days", true)
                        }
                      />
                      <p className="companyLabel p-3">{t("item.days")}</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <DropDown
                        placeholder={"0"}
                        loadOptions={loadHours}
                        defaultOptions={true}
                        isClearable={false}
                        components={{ IndicatorSeparator: () => null }}
                        value={values?.basicInfo?.expirationTime?.hour}
                        onChange={(e) =>
                          setFieldValue("basicInfo.expirationTime.hour", e)
                        }
                        onBlur={() =>
                          setFieldTouched("basicInfo.expirationTime.hour", true)
                        }
                      />
                      <p className="companyLabel p-3">{t("item.hours")}</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <DropDown
                        placeholder={"0"}
                        loadOptions={loadMinutes}
                        isClearable={false}
                        defaultOptions={true}
                        components={{ IndicatorSeparator: () => null }}
                        value={values?.basicInfo?.expirationTime?.min}
                        onChange={(e) =>
                          setFieldValue("basicInfo.expirationTime.min", e)
                        }
                        onBlur={() =>
                          setFieldTouched("basicInfo.expirationTime.min", true)
                        }
                      />
                      <p className="companyLabel p-3">{t("item.minutes")}</p>
                    </div>
                  </div>
                  <small className="text-danger ms-1">
                    {touched?.basicInfo?.expirationTime &&
                      errors?.basicInfo?.expirationTime}
                  </small>
                </div>
              </Col>
              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="name" className="companyLabel">
                    {t("item.thawExpiration")}
                  </label>
                  <div className="d-flex justify-content-between pe-4">
                    <div className="d-flex align-items-center">
                      <DropDown
                        placeholder={"0"}
                        loadOptions={loadDays}
                        isClearable={false}
                        defaultOptions={true}
                        components={{ IndicatorSeparator: () => null }}
                        value={values?.basicInfo?.thawExpiration?.days}
                        onChange={(e) =>
                          setFieldValue("basicInfo.thawExpiration.days", e)
                        }
                        onBlur={() =>
                          setFieldTouched("basicInfo.thawExpiration.days", true)
                        }
                      />
                      <p className="companyLabel p-3">{t("item.days")}</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <DropDown
                        placeholder={"0"}
                        loadOptions={loadHours}
                        isClearable={false}
                        defaultOptions={true}
                        components={{ IndicatorSeparator: () => null }}
                        value={values?.basicInfo?.thawExpiration?.hour}
                        onChange={(e) =>
                          setFieldValue("basicInfo.thawExpiration.hour", e)
                        }
                        onBlur={() =>
                          setFieldTouched("basicInfo.thawExpiration.hour", true)
                        }
                      />
                      <p className="companyLabel p-3">{t("item.hours")}</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <DropDown
                        placeholder={"0"}
                        loadOptions={loadMinutes}
                        isClearable={false}
                        defaultOptions={true}
                        components={{ IndicatorSeparator: () => null }}
                        value={values?.basicInfo?.thawExpiration?.min}
                        onChange={(e) =>
                          setFieldValue("basicInfo.thawExpiration.min", e)
                        }
                        onBlur={() =>
                          setFieldTouched("basicInfo.thawExpiration.min", true)
                        }
                      />
                      <p className="companyLabel p-3">{t("item.minutes")}</p>
                    </div>
                  </div>
                  <small className="text-danger ms-1">
                    {touched?.basicInfo?.thawExpiration &&
                      errors?.basicInfo?.thawExpiration}
                  </small>
                </div>
              </Col>
              <Col xl={6}>
                <label htmlFor="name" className="companyLabel">
                  {t("item.endOfDayExpiration")}
                </label>
                <div className="select_Role d-flex align-items-center">
                  <div>
                    <input
                      type="radio"
                      id="yesEndOfDayExpiration"
                      name="basicInfo.endOfDayExpiration"
                      value={"true"}
                      checked={values?.basicInfo?.endOfDayExpiration == "true"}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="yesEndOfDayExpiration"
                      className="companyLabel"
                    >
                      {t("item.yes")}
                    </label>
                  </div>
                  <div className="ms-5">
                    <input
                      type="radio"
                      id="noEndofDayExpiration"
                      name="basicInfo.endOfDayExpiration"
                      value={"false"}
                      checked={values?.basicInfo?.endOfDayExpiration == "false"}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="noEndofDayExpiration"
                      className="companyLabel"
                    >
                      {t("item.no")}
                    </label>
                  </div>
                </div>
              </Col>
              <Col xl={6}>
                <label htmlFor="name" className="companyLabel">
                  {t("item.thawEndOfDayExpiration")}
                </label>
                <div className="select_Role d-flex align-items-center">
                  <div>
                    <input
                      type="radio"
                      id="thawEndOfDayExporationYes"
                      name="basicInfo.thawEndOfDayExporation"
                      value={"true"}
                      checked={
                        values?.basicInfo?.thawEndOfDayExporation == "true"
                      }
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="thawEndOfDayExporationYes"
                      className="companyLabel"
                    >
                      {t("item.yes")}
                    </label>
                  </div>
                  <div className="ms-5">
                    <input
                      type="radio"
                      id="thawEndOfDayExporationNo"
                      name="basicInfo.thawEndOfDayExporation"
                      value={"false"}
                      checked={
                        values?.basicInfo?.thawEndOfDayExporation == "false"
                      }
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="thawEndOfDayExporationNo"
                      className="companyLabel"
                    >
                      {t("item.no")}
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
          </section>
          <section>
            <Row>
              <Col xl={12}>
                <h3 className="fontSize16 pt-4">
                  {t("item.printAccordingToLabelProfile")}
                </h3>
                <div className="select_Role d-flex align-items-center">
                  <div>
                    <input
                      type="radio"
                      id="specificLabel.needToPrintYes"
                      name="specificLabel.needToPrint"
                      value={"true"}
                      checked={values?.specificLabel?.needToPrint == "true"}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="specificLabel.needToPrintYes"
                      className="companyLabel"
                    >
                      {t("item.yes")}
                    </label>
                  </div>
                  <div className="ms-5">
                    <input
                      type="radio"
                      id="specificLabel.needToPrintNo"
                      name="specificLabel.needToPrint"
                      value={"false"}
                      checked={values?.specificLabel?.needToPrint == "false"}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue("specificLabel.chooseLabel", "");
                      }}
                    />
                    <label
                      htmlFor="specificLabel.needToPrintNo"
                      className="companyLabel"
                    >
                      {t("item.no")}
                    </label>
                  </div>
                </div>
              </Col>

              {values?.specificLabel?.needToPrint == "true" && (
                <Col md={12}>
                  <div className="mt-3">
                    <label htmlFor="name" className="companyLabel">
                      {t("item.chooseLabel")}
                    </label>
                    <input
                      type="text"
                      id="basicInfo.en_name"
                      name="specificLabel.chooseLabel"
                      value={values?.specificLabel?.chooseLabel}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="commonInput"
                      placeholder={t("item.selectLabel")}
                    />

                    <small className="text-danger ms-1">
                      {touched?.specificLabel?.chooseLabel &&
                        errors?.specificLabel?.chooseLabel}
                    </small>
                  </div>
                </Col>
              )}
            </Row>
          </section>
          {/* <section>
            <Row>
              <Col xl={12}>
                <h3 className="fontSize16">{t("item.productWasteTracking")}</h3>
                <div className="select_Role d-flex align-items-center">
                  <div>
                    <input
                      type="radio"
                      id="wasteTracking?.productWasteTrackingYes"
                      name="wasteTracking.productWasteTracking"
                      checked={
                        values?.wasteTracking?.productWasteTracking == "true"
                      }
                      value={"true"}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="wasteTracking?.productWasteTrackingYes"
                      className="companyLabel"
                    >
                      {t("item.yes")}
                    </label>
                  </div>
                  <div className="ms-5">
                    <input
                      type="radio"
                      id="wasteTracking?.productWasteTrackingNo"
                      name="wasteTracking.productWasteTracking"
                      checked={
                        values?.wasteTracking?.productWasteTracking == "false"
                      }
                      value={"false"}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="wasteTracking?.productWasteTrackingNo"
                      className="companyLabel"
                    >
                      {t("item.no")}
                    </label>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="unitOfMeasure" className="companyLabel">
                    {t("item.unitOfMeasure")}
                  </label>

                  <DropDown
                    defaultOptions={true}
                    loadOptions={loadUnits}
                    isClearable={false}
                    onChange={(e) =>
                      setFieldValue("wasteTracking.unitOfMeasure", e)
                    }
                    onBlur={() =>
                      setFieldTouched("wasteTracking.unitOfMeasure", true)
                    }
                    placeholder={t("receiving.selectUnitMeasure")}
                    value={values?.wasteTracking?.unitOfMeasure}
                  />
                  <small className="text-danger ms-1">
                    {touched?.wasteTracking?.unitOfMeasure &&
                      errors?.wasteTracking?.unitOfMeasure?.value}
                  </small>
                </div>
              </Col>
              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="costOfGoods" className="companyLabel">
                    {t("item.costOfGoods")}
                  </label>
                  <input
                    type="text"
                    id="costOfGoods"
                    name="wasteTracking.costOfGoods"
                    value={values?.wasteTracking?.costOfGoods}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="commonInput"
                    onKeyDown={(e) => decimalOnly(e)}
                    placeholder={t("item.enterCostOfGoods")}
                  />
                  <small className="text-danger ms-1">
                    {touched?.wasteTracking?.costOfGoods &&
                      errors?.wasteTracking?.costOfGoods}
                  </small>
                </div>
              </Col>
            </Row>
          </section> */}
          <section className="mt-4">
            <Row>
              <h3 className="fontSize16">{t("item.additionalInfo")}</h3>
              <Col md={6}>
                <div className="position-relative mt-3">
                  <label htmlFor="additionalInfo.sku" className="companyLabel">
                    {t("item.sku")}
                  </label>
                  <input
                    type="text"
                    id="additionalInfo.sku"
                    className="commonInput"
                    name="additionalInfo.sku"
                    value={values?.additionalInfo?.sku}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("item.enterSku")}
                  />
                  <small className="text-danger ms-1">
                    {touched?.additionalInfo?.sku &&
                      errors?.additionalInfo?.sku}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="itemSellPrice" className="companyLabel">
                    {t("item.itemSellPrice")}
                  </label>
                  <input
                    type="text"
                    id="itemSellPrice"
                    name="additionalInfo.itemSellPrice"
                    className="commonInput"
                    value={values?.additionalInfo?.itemSellPrice}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={(e) => decimalOnly(e)}
                    placeholder={t("item.enterItemSellPrice")}
                  />
                  <small className="text-danger ms-1">
                    {touched?.additionalInfo?.itemSellPrice &&
                      errors?.additionalInfo?.itemSellPrice}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="netWeight" className="companyLabel">
                    {t("item.netWeight")}
                  </label>
                  <input
                    type="text"
                    id="netWeight"
                    name="additionalInfo.netWeight"
                    className="commonInput"
                    value={values?.additionalInfo?.netWeight}
                    placeholder={t("item.enterNetWeight")}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.additionalInfo?.netWeight &&
                      errors?.additionalInfo?.netWeight}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="customField" className="companyLabel">
                    {t("item.customField")}
                  </label>
                  <input
                    type="text"
                    id="customField"
                    name="additionalInfo.customField"
                    className="commonInput"
                    value={values?.additionalInfo?.customField}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("item.writeHere")}
                  />
                  <small className="text-danger ms-1">
                    {touched?.additionalInfo?.customField &&
                      errors?.additionalInfo?.customField}
                  </small>
                </div>
              </Col>

              <Col md={12}>
                <div className="mt-3">
                  <label htmlFor="description" className="companyLabel">
                    {t("item.description")}
                  </label>
                  <textarea
                    rows={4}
                    id="description"
                    name="additionalInfo.description"
                    className="commonInput"
                    value={values?.additionalInfo?.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("item.descriptionPlaceholder")}
                  />
                  <small className="text-danger ms-1">
                    {touched?.additionalInfo?.description &&
                      errors?.additionalInfo?.description}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="bar_code mt-3 position-relative">
                  <label htmlFor="barCode" className="companyLabel">
                    {t("item.barcode")}
                  </label>
                  <input
                    type="text"
                    id="barCode"
                    name="additionalInfo.barCode"
                    className="commonInput"
                    value={values?.additionalInfo?.barCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={12}
                    placeholder={t("item.enterBarcodeNumber")}
                    onKeyDown={(e) => decimalOnly(e)}
                  />
                  <p
                    className="generate-text"
                    role="button"
                    onClick={() => {
                      if (!!values?.additionalInfo?.barCode) {
                        setShowBarCode(true);
                        setFieldValue("additionalInfo.newPickedBarcode", "");
                      }
                    }}
                  >
                    {t("item.generate")}
                  </p>
                  <div
                    className="file_Upload mt-3"
                    {...barCodeDrop.getRootProps()}
                  >
                    <div className="dropzone d-flex align-items-center">
                      <input {...barCodeDrop.getInputProps()} />
                      {showBarCode ? (
                        <div className="barcode-svg">
                          <Barcode value={values?.additionalInfo?.barCode} />
                        </div>
                      ) : (
                        <>
                          {!(
                            values.additionalInfo?.newPickedBarcode ||
                            values?.additionalInfo?.barCodeImg
                          ) &&
                            !barCodeDrop.isDragActive && (
                              <div>
                                <p className="text-center me-4">
                                  {t("item.orUploadHere")}
                                </p>
                                <button className="userBtn btn-theme fontSize18 uploadFile_Btn mt-0">
                                  <UploadIcon />
                                  <span className="ms-2">
                                    {t("item.upload")}
                                  </span>
                                </button>
                              </div>
                            )}

                          {(values?.additionalInfo?.newPickedBarcode ||
                            values?.additionalInfo?.barCodeImg) && (
                            <div className="bar-code">
                              <img
                                src={
                                  values.additionalInfo?.newPickedBarcode
                                    ? URL.createObjectURL(
                                        values.additionalInfo?.newPickedBarcode
                                      )
                                    : values?.additionalInfo?.barCodeImg
                                }
                                alt="Preview"
                                className="image-preview"
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <small className="text-danger ms-1">
                  {touched?.additionalInfo?.newPickedBarcode &&
                    errors?.additionalInfo?.newPickedBarcode}
                </small>
              </Col>

              <Col md={6}>
                <div className="qr_code mt-3">
                  <label htmlFor="customer" className="companyLabel">
                    {t("item.qrCode")}
                  </label>
                  <div className="file_Upload" {...getRootProps()}>
                    <div className="dropzone">
                      <input {...getInputProps()} />
                      {!(
                        values?.additionalInfo?.newPickedQrCode ||
                        values?.additionalInfo?.qrCode
                      ) &&
                        !isDragActive && (
                          <div>
                            <p className="text-center">
                              {t("item.uploadIcon")}
                            </p>

                            <button className="userBtn btn-theme fontSize18 uploadFile_Btn">
                              <UploadIcon />
                              <span className="ms-2">{t("item.upload")}</span>
                            </button>
                          </div>
                        )}

                      {(values?.additionalInfo?.newPickedQrCode ||
                        values?.additionalInfo?.qrCode) && (
                        <div className="qr-code">
                          <img
                            src={
                              values.additionalInfo?.newPickedQrCode
                                ? URL.createObjectURL(
                                    values.additionalInfo?.newPickedQrCode
                                  )
                                : values?.additionalInfo?.qrCode
                            }
                            alt="Preview"
                            className="image-preview"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <small className="text-danger ms-1">
                  {touched?.additionalInfo?.newPickedQrCode &&
                    errors?.additionalInfo?.newPickedQrCode}
                </small>
              </Col>
            </Row>
          </section>
          <section>
            <Row>
              <h3 className="fontSize16">{t("item.nutritionalInfo")}</h3>
              <Col md={6}>
                <div className="position-relative mt-3">
                  <label
                    htmlFor="nutritionalInfo.ingredients"
                    className="companyLabel"
                  >
                    {t("item.ingredients")}
                  </label>
                  <input
                    type="text"
                    id="nutritionalInfo.ingredients"
                    name="nutritionalInfo.ingredients"
                    className="commonInput"
                    value={values?.nutritionalInfo?.ingredients}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("item.enterIngredients")}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.ingredients &&
                      errors?.nutritionalInfo?.ingredients}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="position-relative mt-3">
                  <label
                    htmlFor="nutritionalInfo.allergens"
                    className="companyLabel"
                  >
                    {t("item.allergens")}
                  </label>
                  <input
                    type="text"
                    id="nutritionalInfo.allergens"
                    name="nutritionalInfo.allergens"
                    className="commonInput"
                    value={values?.nutritionalInfo?.allergens}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("item.enterAllergens")}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.allergens &&
                      errors?.nutritionalInfo?.allergens}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="position-relative mt-3">
                  <label
                    htmlFor="nutritionalInfo.servingSize"
                    className="companyLabel"
                  >
                    {t("item.servingSize")}
                  </label>
                  <input
                    type="text"
                    id="nutritionalInfo.servingSize"
                    name="nutritionalInfo.servingSize"
                    className="commonInput"
                    value={values?.nutritionalInfo?.servingSize}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={(e) => decimalOnly(e)}
                    placeholder={t("item.enterServingSize")}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.servingSize &&
                      errors?.nutritionalInfo?.servingSize}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label
                    htmlFor="servingsPerContainer"
                    className="companyLabel"
                  >
                    {t("item.servingsPerContainer")}
                  </label>
                  <input
                    type="text"
                    id="servingsPerContainer"
                    name="nutritionalInfo.servingPerContainer"
                    className="commonInput"
                    placeholder={t("item.enterServingsPerContainer")}
                    value={values?.nutritionalInfo?.servingPerContainer}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.servingPerContainer &&
                      errors?.nutritionalInfo?.servingPerContainer}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="calories" className="companyLabel">
                    {t("item.calories")}
                  </label>
                  <input
                    type="text"
                    id="calories"
                    name="nutritionalInfo.calories"
                    className="commonInput"
                    placeholder={t("item.enterCalories")}
                    value={values?.nutritionalInfo?.calories}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={(e) => decimalOnly(e)}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.calories &&
                      errors?.nutritionalInfo?.calories}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="totalFat" className="companyLabel">
                    {t("item.totalFat")}
                  </label>
                  <input
                    type="text"
                    id="totalFat"
                    name="nutritionalInfo.totalFat"
                    className="commonInput"
                    placeholder={t("item.enterTotalFat")}
                    value={values?.nutritionalInfo?.totalFat}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.totalFat &&
                      errors?.nutritionalInfo?.totalFat}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="saturatedFat" className="companyLabel">
                    {t("item.saturatedFat")}
                  </label>
                  <input
                    type="text"
                    id="saturatedFat"
                    name="nutritionalInfo.saturatedFat"
                    className="commonInput"
                    placeholder={t("item.enterSaturatedFat")}
                    value={values?.nutritionalInfo?.saturatedFat}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.saturatedFat &&
                      errors?.nutritionalInfo?.saturatedFat}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="transFat" className="companyLabel">
                    {t("item.transFat")}
                  </label>
                  <input
                    type="text"
                    id="transFat"
                    name="nutritionalInfo.transFat"
                    className="commonInput"
                    placeholder={t("item.enterTransFat")}
                    value={values?.nutritionalInfo?.transFat}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.transFat &&
                      errors?.nutritionalInfo?.transFat}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="caloriesFromFat" className="companyLabel">
                    {t("item.caloriesFromFat")}
                  </label>
                  <input
                    type="text"
                    id="caloriesFromFat"
                    name="nutritionalInfo.caloriesFromFat"
                    className="commonInput"
                    placeholder={t("item.enterCaloriesFromFat")}
                    value={values?.nutritionalInfo?.caloriesFromFat}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.caloriesFromFat &&
                      errors?.nutritionalInfo?.caloriesFromFat}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="cholesterol" className="companyLabel">
                    {t("item.cholesterol")}
                  </label>
                  <input
                    type="text"
                    id="cholesterol"
                    name="nutritionalInfo.cholesterol"
                    className="commonInput"
                    placeholder={t("item.enterCholesterol")}
                    value={values?.nutritionalInfo?.cholesterol}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.cholesterol &&
                      errors?.nutritionalInfo?.cholesterol}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="carbohydrates" className="companyLabel">
                    {t("item.carbohydrates")}
                  </label>
                  <input
                    type="text"
                    id="carbohydrates"
                    name="nutritionalInfo.carbohydrates"
                    className="commonInput"
                    placeholder={t("item.enterCarbohydrates")}
                    value={values?.nutritionalInfo?.carbohydrates}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.carbohydrates &&
                      errors?.nutritionalInfo?.carbohydrates}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="protein" className="companyLabel">
                    {t("item.protein")}
                  </label>
                  <input
                    type="text"
                    id="protein"
                    name="nutritionalInfo.protein"
                    className="commonInput"
                    placeholder={t("item.enterProtein")}
                    value={values?.nutritionalInfo?.protein}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.protein &&
                      errors?.nutritionalInfo?.protein}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="dietaryFiber" className="companyLabel">
                    {t("item.dietaryFiber")}
                  </label>
                  <input
                    type="text"
                    id="dietaryFiber"
                    name="nutritionalInfo.dietaryFiber"
                    className="commonInput"
                    placeholder={t("item.enterDietaryFiber")}
                    value={values?.nutritionalInfo?.dietaryFiber}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.dietaryFiber &&
                      errors?.nutritionalInfo?.dietaryFiber}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="totalSugar" className="companyLabel">
                    {t("item.totalSugar")}
                  </label>
                  <input
                    type="text"
                    id="totalSugar"
                    name="nutritionalInfo.totalSugar"
                    className="commonInput"
                    placeholder={t("item.enterTotalSugar")}
                    value={values?.nutritionalInfo?.totalSugar}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.totalSugar &&
                      errors?.nutritionalInfo?.totalSugar}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="addedSugar" className="companyLabel">
                    {t("item.addedSugar")}
                  </label>
                  <input
                    type="text"
                    id="addedSugar"
                    name="nutritionalInfo.addedSugar"
                    className="commonInput"
                    placeholder={t("item.enterAddedSugar")}
                    value={values?.nutritionalInfo?.addedSugar}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.addedSugar &&
                      errors?.nutritionalInfo?.addedSugar}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="sodium" className="companyLabel">
                    {t("item.sodium")}
                  </label>
                  <input
                    type="text"
                    id="sodium"
                    name="nutritionalInfo.sodium"
                    className="commonInput"
                    placeholder={t("item.enterSodium")}
                    value={values?.nutritionalInfo?.sodium}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.sodium &&
                      errors?.nutritionalInfo?.sodium}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="vitaminA" className="companyLabel">
                    {t("item.vitaminA")}
                  </label>
                  <input
                    type="text"
                    id="vitaminA"
                    name="nutritionalInfo.vitaminA"
                    className="commonInput"
                    placeholder={t("item.enterVitaminA")}
                    value={values?.nutritionalInfo?.vitaminA}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.vitaminA &&
                      errors?.nutritionalInfo?.vitaminA}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="vitaminC" className="companyLabel">
                    {t("item.vitaminC")}
                  </label>
                  <input
                    type="text"
                    id="vitaminC"
                    name="nutritionalInfo.vitaminC"
                    className="commonInput"
                    placeholder={t("item.enterVitaminC")}
                    value={values?.nutritionalInfo?.vitaminC}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.vitaminC &&
                      errors?.nutritionalInfo?.vitaminC}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="vitaminDmcg" className="companyLabel">
                    {t("item.vitaminDmcg")}
                  </label>
                  <input
                    type="text"
                    id="vitaminDmcg"
                    name="nutritionalInfo.vitaminD"
                    className="commonInput"
                    placeholder={t("item.enterVitaminDmcg")}
                    value={values?.nutritionalInfo?.vitaminD}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.vitaminD &&
                      errors?.nutritionalInfo?.vitaminD}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="vitaminDpercent" className="companyLabel">
                    {t("item.vitaminDpercent")}
                  </label>
                  <input
                    type="text"
                    id="vitaminDpercent"
                    name="nutritionalInfo.vitaminDPercent"
                    className="commonInput"
                    placeholder={t("item.enterVitaminDpercent")}
                    value={values?.nutritionalInfo?.vitaminDPercent}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.vitaminDPercent &&
                      errors?.nutritionalInfo?.vitaminDPercent}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="calcium" className="companyLabel">
                    {t("item.calcium")}
                  </label>
                  <input
                    type="text"
                    id="calcium"
                    name="nutritionalInfo.calcium"
                    className="commonInput"
                    placeholder={t("item.enterCalcium")}
                    value={values?.nutritionalInfo?.calcium}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.calcium &&
                      errors?.nutritionalInfo?.calcium}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="ironMg" className="companyLabel">
                    {t("item.ironMg")}
                  </label>
                  <input
                    type="text"
                    id="ironMg"
                    name="nutritionalInfo.iron"
                    className="commonInput"
                    placeholder={t("item.enterIronMg")}
                    value={values?.nutritionalInfo?.iron}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.iron &&
                      errors?.nutritionalInfo?.iron}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="ironPercent" className="companyLabel">
                    {t("item.ironPercent")}
                  </label>
                  <input
                    type="text"
                    id="ironPercent"
                    name="nutritionalInfo.ironPercent"
                    className="commonInput"
                    placeholder={t("item.enterIronPercent")}
                    value={values?.nutritionalInfo?.ironPercent}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.ironPercent &&
                      errors?.nutritionalInfo?.ironPercent}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="potassiumMg" className="companyLabel">
                    {t("item.potassiumMg")}
                  </label>
                  <input
                    type="text"
                    id="potassiumMg"
                    name="nutritionalInfo.potassium"
                    className="commonInput"
                    placeholder={t("item.enterPotassiumMg")}
                    value={values?.nutritionalInfo?.potassium}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.potassium &&
                      errors?.nutritionalInfo?.potassium}
                  </small>
                </div>
              </Col>

              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="potassiumPercent" className="companyLabel">
                    {t("item.potassiumPercent")}
                  </label>
                  <input
                    type="text"
                    id="potassiumPercent"
                    name="nutritionalInfo.potassiumPercent"
                    className="commonInput"
                    placeholder={t("item.enterPotassiumPercent")}
                    value={values?.nutritionalInfo?.potassiumPercent}
                    onChange={handleChange}
                    onKeyDown={(e) => decimalOnly(e)}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.nutritionalInfo?.potassiumPercent &&
                      errors?.nutritionalInfo?.potassiumPercent}
                  </small>
                </div>
              </Col>
            </Row>
          </section>
          <Row>
            <Col className="mt-4" xl={12}>
              <button
                className="userBtn btn-theme m-0"
                type="button"
                onClick={handleSubmit}
              >
                {!!id ? t("globals.saveChanges") : t("globals.add")}
              </button>
            </Col>
          </Row>
        </div>
      </div>
      {(imageMutation.isPending || mutation.isPending) && <Loader />}
    </div>
  );
};

export default AddItem;
