import React from "react";
import { NavbarBrand } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import useSlider from "../../hooks/useSlider";
import AdminHeader from "../AdminHeader";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import "./sidebar.css";
import { useQuery } from "@tanstack/react-query";
import { getUserDetails } from "../../services/services";
import { useDispatch } from "react-redux";
import { user } from "../../redux/features/userSlice";

const Sidebar = ({ sideBar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isSlider = useSlider();

  useQuery({
    queryKey: ["user-details", location.pathname],
    queryFn: async () => {
      const resp = await getUserDetails("", true);
      dispatch(user(resp?.data?.data));
      return true;
    },
  });

  return (
    <>
      <AdminHeader sideBar={sideBar} />
      {sideBar ? (
        <></>
      ) : (
        <div className={isSlider ? "sidebarmain close" : "sidebarmain open"}>
          <>
            <NavbarBrand
              onClick={() => navigate("/dashboard")}
              className="admin-logo"
            >
              <span
                style={{ fontSize: "32px", color: "white", cursor: "pointer" }}
              >
                <img src="/images/logo.svg"></img>
              </span>
            </NavbarBrand>
            <div className="sidelist">
              {SidebarData.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })}
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default Sidebar;
